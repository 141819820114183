<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="320"
    :loading="paying"
    @close="onClose"
    @ok="onSubmitFun"
  >
  <a-spin tip="正在支付..." :spinning="loading">
    <h1>选择支付方式：</h1>
    <div class="pay-type">
      <a-radio-group v-model:value="payType" @change="onChangePayType">
        <a-radio style="line-height: 30px" :value="1">全民付</a-radio>
        <a-radio style="line-height: 30px" :value="5">现金支付</a-radio>
        <a-radio style="line-height: 30px" :value="7">微信</a-radio>
        <a-radio style="line-height: 30px" :value="6">支付宝</a-radio>
        <a-radio style="line-height: 30px" :value="4">刷卡支付</a-radio>
        <a-radio style="line-height: 30px" :value="9">齐商银行</a-radio>
        <a-radio style="line-height: 30px" :value="20">货款冲抵</a-radio>
      </a-radio-group>
    </div>

    <e-info
      :labelCol="6"
      :valueCol="14"
      title="账户余额"
      v-if="finAccount"
      :value="'¥' + finAccount.balance"
    />
    <e-input-number
      :labelCol="6"
      :valueCol="14"
      title="充值金额"
      v-model:value="money"
    />
    <e-input-number
      :labelCol="6"
      :valueCol="14"
      title="赠送金额"
      v-model:value="handselMoney"
    />
  </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref, createVNode } from 'vue'
import { ExclamationOutlined } from '@ant-design/icons-vue'
import { message, Modal } from 'ant-design-vue'
import form from '@/mixins/form'
import { FinAccountClass } from '@/apis/fin'
import { UnionPayClass } from '@/apis/order'
import { addEvent, removeEvent } from '@/utils'

const fin = new FinAccountClass()
const union = new UnionPayClass()
export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  components: {},
  setup (props, { emit }) {
    const loading = ref(false)
    const spinning = ref(false)
    const visible = ref(false)
    const title = ref()
    const user = ref()
    const finAccount = ref()
    const money = ref(0)
    const handselMoney = ref(0)
    const remark = ref('')

    const payType = ref(0)
    const payCode = ref('')
    const paying = ref(false)

    const onClose = () => {
      finAccount.value = null
      paying.value = false
      payCode.value = ''
      loading.value = false
      visible.value = false
      removeEvent(window, 'keydown', eventFun)
    }

    const onChangePayType = () => {
      paying.value = false
      payCode.value = ''
      removeEvent(window, 'keydown', eventFun)
    }

    const eventFun = (e) => {
      if (e.key !== 'Enter') {
        if (e.key !== 'Shift') {
          payCode.value += e.key
        }
      }
    }

    const waitingPay = () => {
      paying.value = true
      addEvent(window, 'keydown', eventFun)

      Modal.warning({
        title: '注意！',
        icon: createVNode(ExclamationOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          '请提醒用户将支付宝、微信、云闪付等付款码放置在全民付盒子上方扫码'
        ),
        okText: '取消支付',
        onOk () {
          paying.value = false
          removeEvent(window, 'keydown', eventFun)
        }
      })
    }

    const waitingPayCash = (channel) => {
      paying.value = true

      if (!channel) {
        paying.value = false
        message.error('请选择支付方式')
        return
      }
      Modal.confirm({
        title: '注意！',
        icon: createVNode(ExclamationOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          '请先确认客户是否已经支付'
        ),
        okText: '客户已支付',
        onOk () {
          fin
            .charge({
              finAccountId: finAccount.value.id,
              money: money.value,
              handselMoney: handselMoney.value,
              remark: '吧台充值',
              payChannel: channel
            })
            .then(() => {
              emit('ok')
              onClose()
            })
        },

        onCancel () {
          paying.value = false
        }
      })
    }

    const onSubmitFun = () => {
      if (payType.value === 1) {
        waitingPay()
      } else {
        waitingPayCash(payType.value)
      }
    }

    return {
      visible,
      loading,
      title,
      spinning,
      finAccount,
      money,
      handselMoney,
      user,
      payType,
      payCode,
      paying,
      remark,

      onClose,
      onSubmitFun,
      waitingPayCash,
      waitingPay,
      onChangePayType
    }
  },

  created () {
    this.$watch('payCode', (e) => {
      clearInterval(this.timer)
      this.timer = setTimeout(() => {
        console.log(e)
        if (e) {
          this.pay(e)
        }
      }, 500)
    })
  },

  methods: {
    open (finAccount) {
      this.finAccount = finAccount
      this.title = finAccount.customerName + '：账户充值'
      this.visible = true
    },

    pay (code) {
      this.loading = true
      this.paying = true

      union.boxPayRecharge({
        payAmount: this.money,
        payCode: code
      }).then(() => {
        this.$message.success('支付成功')

        fin
          .charge({
            finAccountId: this.finAccount.id,
            money: this.money,
            handselMoney: this.handselMoney,
            remark: '吧台充值',
            payChannel: 1
          })
          .then(() => {
            this.$emit('ok')
            this.onClose()
          })
      })
    }
  }
})
</script>

<style lang="less" scoped>
.order-content {
  .btns {
    padding-top: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #ccc;
    margin-top: 15px;
  }
}
</style>
